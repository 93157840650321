/*global $:false*/
/*global $:false*/
/*global escape: true */
/*jslint browser: true*/

$(window).resize(function () {
    WindowSize();
    MoveElements();
    moveCTAPanel();
	
	// Equal Heights on wait time boxes
    $("ul.WaitTimes li, #CTAPanels h2, #CTAPanels .cmspanel, #CareersHome #BelowMainLeftRight h2, #CareersLeftCol #BelowMainLeftRight h2, .grid-2col li, .grid-4col li").css('height', 'auto');
    equalHeight($("ul.WaitTimes li"));
	equalHeight($("#CTAPanels h2"));
	equalHeight($("#CTAPanels .cmspanel"));
	equalHeight($("#CareersHome #BelowMainLeftRight h2"));
	equalHeight($("#CareersLeftCol #BelowMainLeftRight h2"));
	equalHeight($(".grid-2col li"));
	equalHeight($(".grid-4col li"));
	equalHeight($(".grid-4col li"));
});
$(document).ready(function () {

    WindowSize();
    MoveElements();
    moveCTAPanel();

	// Equal Heights on wait time boxes
    $("ul.WaitTimes li").css('height', 'auto');
	equalHeight($("ul.WaitTimes li"));
//	equalHeight($("#CTAPanels h2"));
//	equalHeight($("#CTAPanels .cmspanel"));
	equalHeight($("#CareersHome #BelowMainLeftRight h2"));
	equalHeight($("#CareersLeftCol #BelowMainLeftRight h2"));
	equalHeight($(".grid-2col li"));
	equalHeight($(".grid-4col li"));
 	
	$('#left-menu').sidr({
        name: 'sidr-left',
        side: 'left' // By default
    });
    $('#right-menu').sidr({
        name: 'sidr-right',
        side: 'right'
    });
    $('.MobileSmall #Banner .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });
    $('.MobileSmall .FlexBanner .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });

    // $('#Content .Inline-Block.CampusMap a').removeClass('fancybox');


    //Since we are adding the ability to 'Move-Inline-CTA-Here' for mobile and desktop, we have to add a starting point so we can move it back on desktop
    if ($('#Content .CTA, #Content .CTAWide').length > 0) {
        $('<div class="CTAOrig"></div').insertAfter('#Content .CTA, #Content .CTAWide');
    }

});
$(window).on("load",function() {
	$("#CTAPanels h2, #CTAPanels .cmspanel").css('height', 'auto');
	equalHeight($("#CTAPanels h2"));
	equalHeight($("#CTAPanels .cmspanel"));
});

function WindowSize() {
    var w = Math.max($(window).width(), window.innerWidth);
    var type = 'Desktop';

    //alert(w);

    // css at 800 for Mobile and 1000 for Tablet
    if (w <= 1000) {
        type = 'Tablet';
    }

    // css at 660 for Mobile and 1000 for Tablet
    if (w <= 800) {
        type = 'Mobile';
    }

    // css at 660 for Mobile and 1000 for Tablet
    if (w <= 600) {
        type = 'MobileSmall';
    }

    // change the css file of the tag with id="stl" and rel="stylesheet"
    $('body').removeClass('Desktop').removeClass('Tablet').removeClass('Mobile').removeClass('MobileSmall').addClass(type);
}

//make columns equal heights
function equalHeight(group) {
	var tallest = 0;
	group.each(function () {
		var thisHeight = $(this).height();
		if (thisHeight > tallest) {
			tallest = thisHeight;
		}
	 });
	group.css('height', tallest);
}

function MoveElements() {

	// Equal Heights for Wait Time boxes

    // this is so equal heights runs after ajax
    // $(document).ajaxStop(function(group){
    //   moveElements();
    // });

    if ($("body.Desktop").length) {
        if ($("body").hasClass("sidr-open")) {

            //alert("help");
            $.sidr('close', 'sidr-right');
            $.sidr('close', 'sidr-left');
        }
    }

    $('.MobileSmall #Banner .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });

    $('.MobileSmall .FlexBanner .focuspoint').focusPoint({
        reCalcOnWindowResize: true,
        throttleDuration: 100,
    });

    $('body.MobileSmall #MainNavWrap').appendTo('#sidr-right');
    $('body.MobileSmall #SecondaryNavWrap').insertAfter('#MainNavWrap');
    $('body.Mobile #MainNavWrap').appendTo('#sidr-right');
    $('body.Mobile #SecondaryNavWrap').insertAfter('#MainNavWrap');
    $('body.Tablet #MainNavWrap').appendTo('#sidr-right');
    $('body.Tablet #SecondaryNavWrap').insertAfter('#MainNavWrap');
    $('body.Desktop #SecondaryNavWrap').insertAfter('#Payload');
    $('body.Desktop #MainNavWrap').insertAfter('#HeaderWrap');

    $('body.MobileSmall #MyMidMichiganWrap').appendTo('#sidr-left');
    $('body.MobileSmall #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
    $('body.Mobile #MyMidMichiganWrap').appendTo('#sidr-left');
    $('body.Mobile #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
    $('body.Tablet #MyMidMichiganWrap').appendTo('#sidr-left');
    $('body.Tablet #QuickLinksWrap').insertAfter('#MyMidMichiganWrap');
    $('body.Desktop #MyMidMichiganWrap').appendTo('#HeaderBottom');
    $('body.Desktop #QuickLinksWrap').insertAfter('#MainNav');

    $('body.MobileSmall #ProxNavWrap').appendTo('#ViewMoreContent');
    $('body.Mobile #ProxNavWrap').appendTo('#ViewMoreContent');
    $('body.Tablet #ProxNavWrap').prependTo('#ColumnOne');
    $('body.Desktop #ProxNavWrap').prependTo('#ColumnOne');

    $('body.MobileSmall #ColumnOneWrap').appendTo('#ColumnTwoThree');
    $('body.Mobile #ColumnOneWrap').appendTo('#ColumnTwoThree');
    $('body.Tablet #ColumnOneWrap').prependTo('#Columns');
    $('body.Desktop #ColumnOneWrap').prependTo('#Columns');

    $('body.Desktop #ColumnThree').prependTo('#ColumnThreeWrap');
    $('body.Tablet #ColumnThree').insertAfter('#ColumnOne');
    $('body.Mobile #ColumnThree').insertAfter('#ColumnOne');
    $('body.MobileSmall #ColumnThree').insertAfter('#ColumnOne');

    $('body.Desktop #babyInfoContainer').insertAfter('.babyPhotoContainer');
    $('body.Tablet #babyInfoContainer').insertAfter('.babyPhotoContainer');
    $('body.Mobile #babyInfoContainer').insertAfter('.babyPhotoContainer');
    $('body.MobileSmall #babyInfoContainer').insertBefore('.babyPhotoContainer');

    $('body.Desktop .Designations h1').prependTo('.Designations');
    $('body.Tablet .Designations h1').prependTo('.Designations');
    $('body.Mobile .Designations h1').prependTo('.Designations');
    $('body.MobileSmall .Designations h1').prependTo('.QuickInfo');

    $('body.Desktop #HomeLowerLeftLower').insertAfter('#HomeLowerLeftTop');
    $('body.Tablet #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');
    $('body.Mobile #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');
    $('body.MobileSmall #HomeLowerLeftLower').insertAfter('#HomeLowerRightWrap');


    //wrapping img with .focuspoint, but first checking to see if it exist, if it does do nothing if not wrap the data-focus variables
    if ($('.BannerImg .focuspoint').length > 0) {
        // do nothing
    } else {
        $('body.MobileSmall #Banner img').wrap('<div class="focuspoint" data-focus-x="-0.67" data-focus-y="0.00" data-focus-w="1600" data-focus-h="530" />');
    }

    if ($('.BannerImg .focuspoint').length > 0) {
        // do nothing
    } else {
        $('body.MobileSmall .FlexBanner img').wrap('<div class="focuspoint" data-focus-x="-0.67" data-focus-y="0.00" data-focus-w="1600" data-focus-h="530" />');
    }
}

function moveCTAPanel() {
    /* Call To Action */
    // If Move-CTA-Here exisits - move the CTA to that spot
    // If Move-CTA-Here does not exists - check to see if there is an h1 imediately followed by an h2 and move it after that h2
    // If no h2 exists imediately after an h2, move CTA after the h1 instead
    // If no Move-CTA-Here, H1 or H2 exists - move CTA to the top of the cmsPageContent
	var InsertHere;
    if ($('#LeftCol #CallToActionPanels, #NoCols #CallToActionPanels, #CareersLeftCol #CallToActionPanels').length > 0) {
        if ($('.Move-CTA-Here:first').length > 0) {
            $('#CallToActionPanels').appendTo('.Move-CTA-Here');
        }
        else if ($('.cmsPageContent h1:first').length > 0) {
            if ($('.cmsPageContent  h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('#CallToActionPanels').insertAfter(InsertHere);
            }
            else {
                InsertHere = $('.cmsPageContent h1:first');
                $('#CallToActionPanels').insertAfter(InsertHere);
            }
        } else {
            $('#CallToActionPanels').prependTo('.cmsPageContent');
        }
    } else {
        if ($('#Interior.Tablet .Move-CTA-Here:first, .Mobile .Move-CTA-Here:first').length > 0) {
            $('#Interior.Tablet #ColumnThree #CallToActionPanels').appendTo('#Interior.Tablet .Move-CTA-Here');
            $('.Mobile #ColumnThree #CallToActionPanels').appendTo('.Mobile .Move-CTA-Here');
        }
        else if ($('#Interior.Tablet .cmsPageContent h1:first, .Mobile .cmsPageContent h1:first').length > 0) {
            if ($('#Interior.Tablet .cmsPageContent  h1:first, .Mobile .cmsPageContent h1:first').next('h2').length > 0) {
                InsertHere = $('.cmsPageContent h1:first').next('h2');
                $('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').insertAfter(InsertHere);
            }
            else {
                InsertHere = $('.cmsPageContent h1:first');
                $('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').insertAfter(InsertHere);
            }
        } else {
            $('#Interior.Tablet #ColumnThree #CallToActionPanels, .Mobile #ColumnThree #CallToActionPanels').prependTo('.cmsPageContent');
        }

        $('.Desktop .cmsPageContent #CallToActionPanels').prependTo('#ColumnThree');
        $('#RightCol.Tablet .cmsPageContent #CallToActionPanels').prependTo('#ColumnThree');
    }
    if ($('.Move-Inline-CTA-Here').length > 0) {
        $('.Tablet #Content .CTA, .Mobile #Content .CTA').insertAfter('.Move-Inline-CTA-Here');
        $('.Tablet #Content .CTAWide, .Mobile #Content .CTAWide').insertAfter('.Move-Inline-CTA-Here');
    } 

    $('.Desktop #Content .CTA, .Desktop #Content .CTAWide').insertBefore('.CTAOrig');
    
}